import styled from 'styled-components';
import { SearchField } from './Input';
import { Button } from './Button/Button';
import chevronDown from 'design-system/src/assets/icons/chevronDown.svg';
import { TableDropdownFilter } from './TableDropdownFilter/TableDropdownFilter';

export const TableContainer = styled.div`
  font-size: 0.9rem;
  color: var(--dark-gray);
  overflow-y: scroll;
`;

export const FilterBar = styled.div`
  display: flex;
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--border-gray);
  gap: 1rem;
  flex-direction: column;
`;

export const FilterBarRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  // This is necessary for when the filters are replaced with the bulk actions buttons
  // TODO: consider opacity fading out the filter row contents and placing the bulk
  // action buttons on top using position: absolute so we don't have to hardcode the height
  min-height: 46px;
`;

export const BulkActionsRow = styled(FilterBarRow)`
  justfiy-content: space-between;
`;

export const BulkActionButton = styled(Button)`
  display: flex;
  height: 2rem;
  padding: 0px 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: transparent;
  color: var(--dark-gray);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--border-gray);
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  
  &:hover {
    background-color: var(--light-border-gray);
    color: var(--black);
  }
`;

export const ToggleSelectionCheckbox = styled.button`
  all: unset;
  display: flex;
  width: 18px;
  height: 18px;
`;

export const Table = styled.table`
  width: 100%;
  padding: 0 1.25rem;
  border-collapse: collapse;
  font-size: 1rem;
`;

export const TableHeader = styled.th`
  cursor: pointer;
  padding: 0.625rem;
  border: none;
  text-align: left;
  font-weight: 590;
  border-bottom: 1px solid var(--border-gray);
  white-space: nowrap;
`;

export const TableCell = styled.td`
  padding: 0.625rem;
  border: none;
  color: black;
  font-weight: 400;
`;

export const SearchInput = styled(SearchField)`
  margin-left: auto;
  width: 15.5rem;
`;

export const ClickableRow = styled.tr<{ $selected: boolean; $new?: boolean }>`
  cursor: pointer;
  transition: background-color 0.4s ease-out;

  &:hover {
    background-color: var(--light-border-gray);
  }

  // child TableCell
  & > td {
    ${({ $new }) => ($new ? 'font-weight: bold;' : '')}
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  padding: 0.3rem;
  background-color: var(--surface);
  gap: 0.5rem;
  border-radius: 8px;
`;

export const FilterLabel = styled.button<{ $active: boolean }>`
  color: var(${({ $active }) => ($active ? '--black' : '--dark-gray')});
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 590;
  line-height: 1rem;
  padding: 0.5rem 0.75rem;
  ${({ $active }) => ($active ? 'background-color: var(--white);' : 'background: none;')}
  ${({ $active }) => ($active ? 'box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16);' : '')}
  border-radius: 5px;
  border: none;
  cursor: pointer;
  white-space: nowrap;
`;

export const OfficeNameTableDropdownFilter = styled(TableDropdownFilter)`
  // Office names are especially long
  width: 22.5rem;
`;

export const ActionButton = styled(Button)`
  padding: 0.5rem 0.75rem;
  margin: 0;
`;

const SortIndicatorIcon = styled.img<{ desc: boolean }>`
  transition: transform 0.2s ease-out;
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
  transform: rotate(${({ desc }) => desc ? 0 : 180}deg);
`;

// This container makes sure there is always the same amount of space to the right
// of the header text, regardless of whether the sorting indicator is present, to
// avoid the column width changing when the sorting indicator appears/disappears.
const IconContainer = styled.div`
  width: 1rem;
  display: inline-flex;
  justify-content: flex-end;
  aling-items: center;
`;

export type HeaderSortingIndicatorProps = {
  // Typescript says this can also be a string, but I don't know
  // what string what would be...
  isSorted: boolean | string;
  sortDirection: 'asc' | 'desc' | false;
};

/**
 * Up/down chevron for headers of sorted table columns.
 */
export const HeaderSortingIndicator = ({
  isSorted,
  sortDirection,
}: HeaderSortingIndicatorProps) => {
  return (
    <IconContainer>
      {isSorted ? <SortIndicatorIcon src={chevronDown} desc={sortDirection === 'desc'} /> : null}
    </IconContainer>
  );
};
