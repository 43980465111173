import styled from 'styled-components';
import { Typography } from 'design-system/src/components/Typography/Typography';
import highFive from '../../../assets/highFive.svg';

const Illustration = styled.img`
  width: 18.75rem;
`;

const StyledSignupSuccess = styled.div`
  display: flex;
  gap: 2rem;
  margin: 6rem auto 0;
  flex-direction: column;
  align-items: center;
`;

/**
 * The success page at the end of the signup flow.
 */
export const SignupSuccess = () => {
  return (
    <StyledSignupSuccess>
      <Typography styledAs="h5" as="h1">Thanks! We'll be in touch soon.</Typography>
      <Illustration src={highFive} alt="High five" />
    </StyledSignupSuccess>
  );
};