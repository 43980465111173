import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TasksResponse, client } from '../api';
import PageContainer from '../components/PageContainer';
import TasksTable from '../components/tasks/TasksTable';
import { Outlet, useOutletContext } from 'react-router-dom';
import { tasksQueryKey } from '../queries';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';

type ContextType = { taskIds: string[] | null };

const TasksTablePage: React.FC = () => {
  const [taskIds, setTaskIds] = useState<string[]>([]);

  const { data, isLoading, error } = useQuery<TasksResponse>({
    queryKey: tasksQueryKey(),
    queryFn: () => {
      return client.get(`tasks`).then((res) => res.data);
    },
  });

  let body;
  if (isLoading) {
    body = <LoadingSpinner />;
  } else if (error) {
    body = 'Error, please try again';
  } else if (data) {
    if (data.tasks.length === 0) {
      body = 'No tasks found';
    } else {
      body = <TasksTable data={data.tasks} setTaskIds={setTaskIds} />;
    }
  }

  return (
    <>
      <PageContainer title="Tasks">{body}</PageContainer>
      <Outlet context={{ taskIds } satisfies ContextType} />
    </>
  );
};

export function useTasksIds() {
  return useOutletContext<ContextType>();
}

export default TasksTablePage;
