import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { Typography } from '../Typography/Typography';

type StyleProps = {
  $fullHeight: boolean,
  $fullOpacity: boolean,
}

const animationDuration = 200;

const StyledErrorMessage = styled(Typography) <StyleProps>`
  color: var(--red);

  transform: ${({ $fullHeight }) => $fullHeight ? 'scaleY(1)' : 'scaleY(0)'};
  opacity: ${({ $fullOpacity }) => $fullOpacity ? 1 : 0};

  transition: transform ${animationDuration}ms ease-out, opacity ${animationDuration}ms ease-out;
`;

export type ErrorMessageProps = {
  children: React.ReactNode,
  // True by default
  visible?: boolean,
};

/**
 * A component for displaying error messages.
 * 
 * The message is colored red and will animate in when mounted. If you set the `visible` prop to `false` remove the error message, it will also animate out.
 */
export const ErrorMessage = ({
  children,
  visible = true,
}: ErrorMessageProps) => {
  // We're using a separate state variables for visible styling
  // so that we can animate the height and opacity separately,
  // chaining them using setTimeout
  // We don't want to animate the height and opacity at the same time
  // because the text would be vertically squashed while it's fading in and out
  const [fullHeight, setFullHeight] = useState(false);
  const [fullOpacity, setFullOpacity] = useState(false);

  useEffect(() => {
    if (visible && !fullHeight) {
      setFullHeight(true);

      setTimeout(() => {
        setFullOpacity(true);
        // The timeout should match the height transition duration
        // because we're animating the height up and then the opacity in
      }, animationDuration);
    }

    if (!visible && fullHeight) {
      setFullOpacity(false);

      setTimeout(() => {
        setFullHeight(false);
        // The timeout should match the opacity transition duration
        // because we're animating the opacity out and then the height down
      }, animationDuration);
    }
  }, [visible]);

  return (
    <StyledErrorMessage
      weight={500}
      $fullHeight={fullHeight}
      $fullOpacity={fullOpacity}
      aria-hidden={!visible}
      aria-live='polite'
    >
      {children}
    </StyledErrorMessage>
  );
};