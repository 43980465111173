import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import Markdown from 'react-markdown'
import { client } from '../api';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';

const StyledMarkdownViewer = styled.div`
    font-weight: normal;
    // This stop numbers in parenthesis like (1) from rendering as a number in a circle
    font-variant-ligatures: none;
`;

export const MarkdownViewer: React.FC<{ fileUrl: string }> = ({ fileUrl }) => {
    const query = useQuery({
        queryKey: ['mark-down-viewer', fileUrl],
        queryFn: () => {
            return client.get(fileUrl).then((res) => res.data);
        },
    });

    if (query.isError) {
        // TODO: componentize this
        return <div>We're having trouble downloading the document. Please try again later.</div>;
    }

    if (query.isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <StyledMarkdownViewer>
            <Markdown>{query.data}</Markdown>
        </StyledMarkdownViewer>
    );
};