import { createContext } from 'react';
import { PriorAuthReviewResponse } from '../../api';
import { UseQueryResult } from '@tanstack/react-query';

interface PriorAuthReviewContext {
  priorAuthReviewId: string;
  priorAuthReviewQuery: UseQueryResult<PriorAuthReviewResponse>;
  refetchPriorAuthReview: () => Promise<void>;
  onPriorAuthReviewUpdate: () => Promise<void>;
  updating: boolean;
  setUpdating: (value: boolean) => void;
  priorAuthReviewIds: string[];
}

export const PriorAuthReviewContext = createContext<PriorAuthReviewContext>({} as PriorAuthReviewContext);
