import styled from 'styled-components';
import { TopNavbar } from '../components/TopNavbar/TopNavbar';
import { PrivacyPolicy } from '../components/PrivacyPolicy/PrivacyPolicy';

const Container = styled.div`
    box-sizing: border-box;
    min-height: 100vh;
    min-height: 100dvh;
    height: 100%;
    background-color: var(--light-surface);
`;

const StyledPrivacyPolicy = styled(PrivacyPolicy)`
box-sizing: border-box;
    padding: 0 2rem 2rem 2rem;
    width: 57rem;
    margin: 0 auto;

    @media screen and (max-width: 1025px) {
        width: 100%;
    }
`;

/**
 * A page displaying a document such as the terms of service or business associate agreement.
 */
const SignupDocumentPage = () => {
    return (
        <Container>
            <TopNavbar />
            <StyledPrivacyPolicy />
        </Container>
    );
};

export default SignupDocumentPage;