import styled from 'styled-components';

const StyledTypography = styled.p<{ $weight?: 400 | 500 | 600 }>`
  color: var(--black);
  font-style: normal;
  margin: 0;
  padding: 0;

  &.h1 {
    font-family: 'Parafina', system-ui, Helvetica, Arial, sans-serif;
    font-size: 5rem;
    font-weight: 700;
    line-height: 90%;
    letter-spacing: -1px;
  }
  
  &.h2 {
    font-family: 'Parafina', system-ui, Helvetica, Arial, sans-serif;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 92.857%;
    letter-spacing: -1px;
  }

  &.h3 {
    font-family: 'Parafina', system-ui, Helvetica, Arial, sans-serif;
    font-size: 3rem;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1px;
  }

  &.h4 {
    font-family: 'Parafina', system-ui, Helvetica, Arial, sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 110%;
  }

  &.h5 {
    font-family: 'Parafina', system-ui, Helvetica, Arial, sans-serif;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 114.286%;
  }

  &.title {
    font-family: Haffer, system-ui, Helvetica, Arial, sans-serif;
    font-size: 1.3125rem;
    font-weight: 600;
    line-height: 123.81%;
  }

  &.largeBody {
    font-family: Haffer, system-ui, Helvetica, Arial, sans-serif;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 141.176%;
  }

  &.body {
    font-family: Haffer, system-ui, Helvetica, Arial, sans-serif;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 133.333%;
    letter-spacing: 0.009375rem;
  }

  &.smallBody {
    font-family: Haffer, system-ui, Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 128.571%;
    letter-spacing: 0.009375rem;
  }

  // The default font weights above have higher specificity because they're defined in classes,
  // so we need to use !important to override them here.
  ${({ $weight }) => $weight && `font-weight: ${$weight} !important;`}
`;

export type TypographyProps = {
  children: React.ReactNode;
  className?: string;
  /**
   * The level of typography the text appears as. This will match the style specified in the design.
   * 
   * Defaults to `body`.
   */
  styledAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'title' | 'largeBody' | 'body' | 'smallBody';
  /**
   * The semantic tag of the element rendered. This should match how the text behaves within the context of the page. For example, you can style a link as `smallBody` but render it as an `a` tag.
   * 
   * If unspecified, the `as` prop will default to matching `styledAs`.
   * 
   * When working with headings, be sure not to skip levels. For example, an h2 should not be followed by an h4 (without an h3 in between) even if the next heading is styled like an h4. In that example, you can choose h4 for the `style` and h3 for `as`.
   */
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'a' | 'span' | 'div';
  weight?: 400 | 500 | 600;

  // Allow additional props like `id`, `href`, etc.
  [x: string]: any;
};

/**
 * Renders text with design system styles.
 */
export const Typography = ({ children, className, styledAs, as, weight, ...rest }: TypographyProps) => {
  const styleToUse = styledAs || 'body';

  let asToUse = as;
  if (!as) {
    if (
      styleToUse === 'title' ||
      styleToUse === 'largeBody' ||
      styleToUse === 'body' ||
      styleToUse === 'smallBody'
    ) {
      asToUse = 'p';
    } else {
      asToUse = styleToUse;
    }
  }

  return (
    <StyledTypography
      as={asToUse}
      className={`${styleToUse} ${className ? className : ''}`}
      $weight={weight}
      {...rest}
    >
      {children}
    </StyledTypography>
  );
};