import { createContext } from 'react';
import { TaskResponse } from '../../api';
import { UseQueryResult } from '@tanstack/react-query';

interface TaskContext {
  taskId: string;
  taskQuery: UseQueryResult<TaskResponse>;
  refetchTask: () => Promise<void>;
  onTaskUpdate: () => Promise<void>;
  updating: boolean;
  setUpdating: (value: boolean) => void;
  taskIds: string[];
}

export const TaskContext = createContext<TaskContext>({} as TaskContext);
