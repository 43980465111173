import styled from 'styled-components';
import logoLong from 'design-system/src/assets/icons/logoLong.svg';
import { Typography } from 'design-system/src/components/Typography/Typography';

const StyledTopNavbar = styled.div`
  background-color: var(--light-surface);
  display: flex;
  height: 64px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
`;

const NavbarSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const LogoLink = styled.a`
  text-decoration: none;
  display: flex;
  height: 2.25rem;
  justify-content: center;
  align-items: center;
`;

const TertiaryButtonLink = styled(LogoLink)`
  padding: 0px 1rem;
  color: var(--black);
  font-weight: 500;
`;

const PrimaryButtonLink = styled(TertiaryButtonLink)`
  border-radius: 10px;
  color: var(--white);
  background: var(--purple);

  /* Body/Semibold */
  font-size: 15px;
  font-weight: 600;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.15px;
`;

const Pipe = styled.div`
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 20px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ButtonLinkDesktopOnly = styled(TertiaryButtonLink)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

/**
 * Bar that sits on top of the logged-out homepage
 */
export const TopNavbar = () => {
  return (
    <StyledTopNavbar>
      <LogoLink href='https://www.withtandem.com'><img src={logoLong} alt='tandem' /></LogoLink>

      <NavbarSection>
        <ButtonLinkDesktopOnly href='https://www.withtandem.com/patients'>
          <Typography as='span' weight={500}>
            Patients
          </Typography>
        </ButtonLinkDesktopOnly>
        <ButtonLinkDesktopOnly href='https://www.withtandem.com'>
          <Typography as='span' weight={500}>
            Doctors
          </Typography>
        </ButtonLinkDesktopOnly>

        <Pipe />

        <TertiaryButtonLink href='/login'>
          <Typography as='span' weight={500}>
            Log in
          </Typography>
        </TertiaryButtonLink>
        <PrimaryButtonLink href='/signup'>
          Sign up
        </PrimaryButtonLink>
      </NavbarSection>
    </StyledTopNavbar>
  );
};