import React, { useState } from 'react';
import PageContainer from '../components/PageContainer';
import { Outlet, useOutletContext } from 'react-router-dom';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { client } from '../api';
import { RequestTable } from '../components/requests/RequestTable';

import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';

type ContextType = { requestIds: string[] | null };

const RequestsTablePage: React.FC = () => {
  const [requestIds, setRequestIds] = useState<string[]>([]);

  const query = useQuery({
    queryKey: ['requests'],
    queryFn: () => {
      return client.get(`requests`).then((res) => res.data);
    },
  });

  const queryClient = useQueryClient();

  let body;
  if (query.isLoading) {
    body = <LoadingSpinner />;
  } else if (query.error) {
    body = 'Error, please try again';
  } else if (query.data) {
    body = (
      <>
        <RequestTable data={query.data.requests} setRequestIds={setRequestIds} />
      </>
    );
  }

  return (
    <>
      <PageContainer title="Requests">{body}</PageContainer>
      <Outlet
        context={{
          reload: () => queryClient.resetQueries({ queryKey: ['requests'] }), requestIds
        }}
      />
    </>
  );
};

export function useRequestIds() {
  return useOutletContext<ContextType>();
}

export default RequestsTablePage;
