import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'design-system/src/components/Typography/Typography';
import chevronRight from 'design-system/src/assets/icons/chevronRight.svg';
import chevronUp from 'design-system/src/assets/icons/chevronUp.svg';
// TODO: figure out how to do icons in a way that we can pass the color through with css
import chevronUpGray from 'design-system/src/assets/icons/chevronUpGray.svg';
import chevronDown from 'design-system/src/assets/icons/chevronDown.svg';
import chevronDownGray from 'design-system/src/assets/icons/chevronDownGray.svg';
import layoutWindow from 'design-system/src/assets/icons/layoutWindow.svg';

const StyledItemViewTopNav = styled.div`
  border-bottom: 1px solid var(--light-border-gray);
  // NOTE: the top nav was shifting in size when we were only using 'height'
  min-height: var(--item-view-top-nav-height);
  max-height: var(--item-view-top-nav-height);
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
  // TODO: handle the case where the window is too narrow and causes
  // the breadcrumbs to wrap and overflow
  overflow: hidden;
`;

const Breadcrumbs = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;

  > img {
    width: 0.625rem;
    height: 0.625rem;
  }
`;

const TableLink = styled.a`
  text-decoration: none;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const Ratio = styled.div`
  display: flex;
  gap: 3px;
`;

const DisabledText = styled.div`
  color: var(--disabled-gray);
`;

const UpDownButtons = styled.div`
  display: flex;
`;

const StyledIconButtonLink = styled.a`
  display: flex;
  align-items: center;
  padding: 0.625rem 0.875rem;
`;

const CollapseSidebarButton = styled.button<{ $isSidebarCollapsed: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid rgba(0,0,0,0);
  border-radius: var(--border-radius-medium);
  background-color: ${(props) => props.$isSidebarCollapsed ? 'var(--white);' : 'var(--light-border-gray);'};
`;

export type ItemViewTopNavProps = {
  // Id of the current item being displayed
  itemId: string,
  // Full list of item ids that can be navigated to via up/down buttons
  listIds: string[],
  // The url that the item id is appended to in order to get the full url of the item
  // Example: if the item url is "/appeal-reviews/appeal-review-1", the itemBaseUrl would be "/appeal-reviews"
  itemBaseUrl: string,
  // The url of the table that the item is in. This is used by the breadcrumbs
  urlOfTable: string,
  // This is left side of the breadcrumbs, which takes the user back to the table.
  breadcrumbOrigin: string,
  // This is the right side of the breadcrumbs, which represents the current item.
  breadcrumbCurrent: string,
  isSidebarCollapsed?: boolean,
  setIsSidebarCollapsed?: Dispatch<SetStateAction<boolean>>,
  // If the item has no sidebar to collapse
  noSidebar?: boolean,
};

/**
 * A navigation bar that sits on top of the page. It's designed to work for a detailed view of an item in a table and contains links to navigate back to the table and to other items in the table.
 */
export const ItemViewTopNav = ({
  itemId,
  listIds,
  itemBaseUrl,
  urlOfTable,
  breadcrumbOrigin,
  breadcrumbCurrent,
  isSidebarCollapsed,
  setIsSidebarCollapsed,
  noSidebar,
}: ItemViewTopNavProps) => {
  const itemIndex = listIds.indexOf(itemId);

  let urlOfItemAbove: string | null = null;
  let urlOfItemBelow: string | null = null;

  const ratioLeftNum = itemIndex !== -1 ? itemIndex + 1 : 1;
  const ratioRightNum = itemIndex !== -1 ? listIds.length : 1;

  // This -1 check is necessary because the table may be in a filter state where this
  // item has been filtered out and is not in the list
  if (itemIndex !== -1) {
    const idOfItemAbove = itemIndex > 0 ? listIds[itemIndex - 1] : null;
    const idOfItemBelow = itemIndex < listIds.length - 1 ? listIds[itemIndex + 1] : null;
    urlOfItemAbove = idOfItemAbove ? `${itemBaseUrl}/${idOfItemAbove}` : null;
    urlOfItemBelow = idOfItemBelow ? `${itemBaseUrl}/${idOfItemBelow}` : null;
  }

  const navigate = useNavigate();

  // These handlers prevent the default behavior of the event and navigate to the appropriate URL.
  // The href attribute is not used to navigate between pages; it's there to communicate to
  // screen readers where the link goes.
  const handleBreadcrumbClick = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    navigate(urlOfTable);
  };
  const handleUpNavigation = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    if (urlOfItemAbove) {
      navigate(urlOfItemAbove);
    }
  };
  const handleDownNavigation = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    if (urlOfItemBelow) {
      navigate(urlOfItemBelow);
    }
  };

  const getUpDownButtons = (urlOfItemAbove: string | null, urlOfItemBelow: string | null) => {
    let upButton = null;
    let downButton = null;

    if (urlOfItemAbove) {
      upButton = (
        <StyledIconButtonLink
          onClick={handleUpNavigation}
          href={urlOfItemAbove}
        >
          <img src={chevronUp} alt="next item in table" />
        </StyledIconButtonLink>
      );
    } else {
      upButton = (
        <StyledIconButtonLink as='div'>
          <img src={chevronUpGray} alt="this is the first item in the table" />
        </StyledIconButtonLink>
      );
    }

    if (urlOfItemBelow) {
      downButton = (
        <StyledIconButtonLink
          onClick={handleDownNavigation}
          href={urlOfItemBelow}
        >
          <img src={chevronDown} alt="previous item in table" />
        </StyledIconButtonLink>
      );
    } else {
      downButton = (
        <StyledIconButtonLink as='div'>
          <img src={chevronDownGray} alt="this is the last item in the table" />
        </StyledIconButtonLink>
      );
    }

    return (
      <UpDownButtons>
        {upButton}
        {downButton}
      </UpDownButtons>
    );
  }

  return (
    <StyledItemViewTopNav>
      <Breadcrumbs>
        <TableLink onClick={handleBreadcrumbClick} href={urlOfTable}>
          <Typography styledAs="smallBody" weight={500}>{breadcrumbOrigin}</Typography>
        </TableLink>
        <img src={chevronRight} alt="" />
        <Typography styledAs="smallBody" weight={500}>{breadcrumbCurrent}</Typography>
      </Breadcrumbs>

      <RightSide>
        <Typography styledAs='smallBody' as='div'>
          <Ratio>
            {ratioLeftNum} <DisabledText>/</DisabledText> <DisabledText>{ratioRightNum}</DisabledText>
          </Ratio>
        </Typography>

        {getUpDownButtons(urlOfItemAbove, urlOfItemBelow)}

        {(!noSidebar && isSidebarCollapsed !== undefined && setIsSidebarCollapsed) && (
          <CollapseSidebarButton
            $isSidebarCollapsed={isSidebarCollapsed}
            onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
          >
            <img src={layoutWindow} alt={isSidebarCollapsed ? "expand sidebar" : "collapse sidebar"} />
          </CollapseSidebarButton>
        )}
      </RightSide>

    </StyledItemViewTopNav>
  );
};