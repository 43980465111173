import styled, { keyframes } from 'styled-components';

import { FlexRow } from '../Layout';

// TODO: make this an actual form
export const Form = styled.div<{ $hasError?: boolean }>`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
  align-self: stretch;
  border-radius: 0.75rem;
  border: 2px solid var(--border-gray);
  background: var(--white);
  position: relative;
  overflow: hidden;
  transition: border-color 0.2s ease-out;

  ${({ $hasError }) => $hasError && `border-color: var(--red);`}

  // TODO: set this around the textarea, not on the border itself
  // so it doesn't override the error state
  &:has(:focus-visible) {
    border-color: var(--purple);
  }

  textarea {
    border: none;
    outline: none;
    padding: 0;
    font-size: 1rem;
  }
`;

export const FormOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  backdrop-filter: blur(3px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it sits above other content */
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const FormOverlayLoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  border-top: 3px solid rgba(0, 0, 0, 0.7);
  border-right: 3px solid transparent;
  animation: ${spin} 1s linear infinite;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

export const ResponseInput = styled.textarea`
  border-radius: 6px;
  border: 1px solid var(--border-gray);
  background: var(--white);
  display: flex;
  height: 5.5rem;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  resize: none;
`;

export const ButtonRow = styled(FlexRow)`
justify-content: flex-end;
`;