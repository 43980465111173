import search from '../assets/search.svg';
import { FormLabel } from './FormLabel';

import React from 'react';
import styled from 'styled-components';

type SharedProps = {
  id?: string;
  placeholder?: string;
  label: string;
  // If passed, the label will not be visually displayed.
  // Instead, it will be used as placeholder text and accessibly linked to the input.
  hideLabel?: boolean;
  hasError?: boolean;
};

type InputProps = SharedProps & React.HTMLProps<HTMLInputElement>;

type TextAreaProps = SharedProps & React.HTMLProps<HTMLTextAreaElement>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ id, label, placeholder = '', type = 'text', hasError, ...rest }, ref) => {
    const elementId = id || 'input-id';

    return (
      <InputContainer>
        <FormLabel htmlFor={elementId}>{label}</FormLabel>
        <StyledInput
          id={elementId}
          ref={ref}
          type={type}
          placeholder={placeholder}
          $hasError={hasError}
          {...rest}
        />
      </InputContainer>
    );
  },
);

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ id, label, placeholder = '', ...rest }, ref) => {
    const elementId = id || 'input-id';

    return (
      <InputContainer>
        <FormLabel htmlFor={elementId}>{label}</FormLabel>
        <StyledTextArea id={elementId} ref={ref} placeholder={placeholder} {...rest} />
      </InputContainer>
    );
  },
);

export const SearchField = React.forwardRef<HTMLInputElement, InputProps>(
  ({ id, label, placeholder = '', hideLabel = false, className, ...rest }, ref) => {
    const elementId = id || 'search-field-id';

    return (
      <InputContainer className={className}>
        {!hideLabel && <FormLabel htmlFor={elementId}>{label}</FormLabel>}
        <>
          <StyledInput
            id={elementId}
            ref={ref}
            type="text"
            placeholder={hideLabel ? label : placeholder}
            aria-label={hideLabel ? label : undefined}
            autoComplete="off"
            $icon
            {...rest}
          />
          <SearchIcon src={search} alt="" $hideLabel={hideLabel} />
        </>
      </InputContainer>
    );
  },
);

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const StyledInput = styled.input<{ $icon?: boolean, $hasError?: boolean }>`
  border: 1px solid var(--medium-gray);
  background-color: var(--white);
  border-radius: var(--border-radius-medium);
  padding: 0.875rem 0.75rem 0.875rem ${({ $icon = false }) => ($icon ? '2.75rem' : '0.75rem')};
  transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;

  font-size: 0.9rem;
  font-style: normal;
  line-height: 1rem;
  color: var(--black);

  &:focus-visible {
    outline: none;
    border: 1px solid var(--purple);
    box-shadow: 0 0 2px var(--purple);
    background-color: var(--white);
  }

  ${({ $hasError }) => $hasError && `
    border-color: var(--red);
    
    &:focus-visible {
      border-color: var(--red);
      box-shadow: 0 0 2px var(--red);
    }
  `}
`;

const StyledTextArea = styled.textarea`
  border: 1px solid var(--medium-gray);
  background-color: var(--white);
  border-radius: 0.375rem;
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
  font-size: 1.0625rem;
  line-height: 1.41;

  &:focus-visible {
    outline: none;
    border: 1px solid var(--purple);
    box-shadow: 0 0 2px var(--purple);
    background-color: var(--white);
  }
`;

const SearchIcon = styled.img<{ $hideLabel?: boolean }>`
  position: absolute;
  left: 12px;
  top: ${({ $hideLabel }) => ($hideLabel ? '10px' : '36px')};
`;
