import styled, { keyframes } from 'styled-components';
// TODO: figure out how to alter svg color using fillColor
import loadingWhite from '../assets/icons/loadingWhite.svg';
import loading from '../assets/icons/loading.svg';

const ButtonSubmittingSpinnerContainer = styled.div`
    width: 22px;
    height: 22px;
`;

const spin = keyframes`
from {
  transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledButtonSubmittingSpinner = styled.img`
  animation: ${spin} 1.5s linear infinite;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

/**
 * An animated spinner to be places in buttons during loading states.
 */
export const ButtonSubmittingSpinner = ({ hasLightBackground }: { hasLightBackground?: boolean }) => {
  return (
    <ButtonSubmittingSpinnerContainer>
      <StyledButtonSubmittingSpinner src={hasLightBackground ? loading : loadingWhite} alt="submitting" />
    </ButtonSubmittingSpinnerContainer>
  );
};