import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { client } from '../../api';
import type { PriorAuthReviewFormData, PriorAuthReviewFormUpdateData } from '../../api';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import { BodyContainer, TopNavAndContent, ContentOuterWrapper, CenteredContent } from '../tableItemDetailViewComponents/Containers';
import React, { useContext, useState } from 'react';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import styled from 'styled-components';
import { PriorAuthReviewContext } from './contexts';
import { Button } from '../Button/Button';
import moment from 'moment';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import toast from "react-hot-toast";
import { Toast } from "design-system/src/components/Toast/Toast";
import { CustomPaForm } from './CustomPaForm/CustomPaForm';

// This formats moment to use a single letter for the time unit
// TODO: move to a helper file
moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1M',
    MM: '%dM',
    y: '1Y',
    yy: '%dY'
  }
})

const FormAndFooterContainer = styled.div`
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--border-gray);
  background: var(--white);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10);
  margin: 1.5rem 0.25rem;
`;

const StyledCustomPaFormViewer = styled.div`
  // TODO: programmatically set this height
  // 218 is sum of
  // - StyledItemViewTopNav height
  // - ContentOuterWrapper padding
  // - title typography height
  // - FormAndResultContainer margin (top and bottom)
  // - FormControlsContainer height
  height: calc(100vh - 218px);
  overflow-y: scroll;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid var(--light-border-gray);
  background: var(--white);
  border-bottom-left-radius: var(--border-radius-medium);
  border-bottom-right-radius: var(--border-radius-medium);
  position: relative;
`;

const FooterLoadingSpinner = styled(LoadingSpinner)`
  width: 1.5rem;
  // Horizontally center the spinner regardless of the other
  // buttons in the footer
  position: absolute;
  left: calc(50% - 0.75rem);
`;

const AcceptButton = styled(Button)`
  display: flex;
  height: 2rem;
  padding: 0px 0.75rem;
  align-items: center;
  border-radius: var(--border-radius-medium);
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.15px;
  gap: 0.25rem;
  transition: background-color 0.2s ease-out;

  &:hover {
    // There's no design system color close to this
    background-color: #532e89;
    border-color: #532e89;
  }
`;

const ResetFormButton = styled(AcceptButton)`
  background-color: transparent;
  color: var(--dark-gray);
  border: 1px solid transparent;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: var(--light-border-gray);
    border-color: var(--light-border-gray);
  }
`;

const ResponsesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 0;
  border-top: 1px solid var(--border-gray);
`;

const Response = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ResponseIcon = styled.div`
  font-family: Parafina;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: var(--purple);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;

const ResponseHeader = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  margin-bottom: 2px;
`;

const ExtraSmallText = styled(Typography)`
  font-size: 0.75rem !important;
`;

const PriorAuthReviewView: React.FC = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const queryClient = useQueryClient();

  const {
    priorAuthReviewQuery,
    priorAuthReviewId,
    onPriorAuthReviewUpdate,
    updating,
    setUpdating,
    priorAuthReviewIds,
  } = useContext(PriorAuthReviewContext);

  const formQuery = useQuery<PriorAuthReviewFormData>({
    queryKey: ['customPaFormViewer', priorAuthReviewId],
    queryFn: async () => {
      return client.get(`prior-auth-review/${priorAuthReviewId}/form`).then((res) => res.data);
    },
  });

  const saveQuestionAnswer = useMutation<PriorAuthReviewFormData, AxiosError, PriorAuthReviewFormUpdateData>({
    mutationFn: async (data) => {
      const res = await client.post(`prior-auth-review/${priorAuthReviewId}/form/answer`, data);
      return res.data;
    },
    onSuccess: () => {
      toast.custom(() => <Toast variant='success'>Form saved</Toast>);
      return queryClient.invalidateQueries({ queryKey: ['customPaFormViewer', priorAuthReviewId] });
    },
    onError: () => {
      toast.custom(() => <Toast variant='error'>An error occurred</Toast>);
    }
  });

  const resetPriorAuthReviewForm = useMutation({
    mutationFn: async () => {
      const res = await client.post(`prior-auth-reviews/${priorAuthReviewId}/form/reset`);
      return res.data;
    },
    onSuccess: () => {
      toast.custom(() => <Toast variant='success'>Form cleared</Toast>);
      return queryClient.invalidateQueries({ queryKey: ['customPaFormViewer', priorAuthReviewId] });
    },
    onError: () => {
      toast.custom(() => <Toast variant='error'>An error occurred</Toast>);
    }
  });

  const navigate = useNavigate();
  const acceptPriorAuthReview = useMutation({
    mutationFn: async () => {
      const res = await client.post(`prior-auth-reviews/${priorAuthReviewId}/form/submit`);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onPriorAuthReviewUpdate();
      navigate('/prior-auth-reviews');
      return queryClient.invalidateQueries({ queryKey: ['customPaFormViewer', priorAuthReviewId] });
    },
    onError: () => {
      toast.custom(() => <Toast variant='error'>An error occurred</Toast>);
      setUpdating(false);
    }
  });

  let body;
  if (priorAuthReviewQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (priorAuthReviewQuery.error) {
    body = 'Error, please try again';
  } else if (priorAuthReviewQuery.data) {
    const priorAuthReviewInfo = priorAuthReviewQuery.data.prior_auth_review;
    const priorAuthReviewTitle = `${priorAuthReviewQuery.data.drug.name} (${priorAuthReviewQuery.data.patient.last_name})`;

    let formBody;
    if (formQuery.isLoading) {
      formBody = <LoadingSpinner />;
    }
    if (formQuery.error) {
      formBody = 'Error, please try again';
    }
    if (formQuery.data) {
      formBody = (<CustomPaForm
        form={formQuery.data.form}
        formStatus={formQuery.data.form_status}
        saveQuestionAnswer={saveQuestionAnswer.mutate}
      />)
    }

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav
            itemId={priorAuthReviewId}
            listIds={priorAuthReviewIds}
            itemBaseUrl={"/prior-auth-reviews"}
            urlOfTable="/prior-auth-reviews"
            breadcrumbOrigin='PriorAuthReviews'
            breadcrumbCurrent={priorAuthReviewTitle}
            isSidebarCollapsed={isSidebarCollapsed}
            setIsSidebarCollapsed={setIsSidebarCollapsed}
          />

          <ContentOuterWrapper>
            <CenteredContent>
              {/* TODO: truncate this */}
              <Typography styledAs="title" as="h2">{priorAuthReviewTitle}</Typography>

              {/* TODO: split this block out and share with AppealReviewView */}
              {(priorAuthReviewInfo.status === 'submitted') && (
                <ResponsesContainer>
                  <Response>
                    {/* TODO: replace with edit icon */}
                    {/* @ts-ignore we're going to replace this anyway */}
                    <ResponseIcon>{priorAuthReviewInfo.reviewed_by.charAt(0)}</ResponseIcon>
                    <ResponseHeader>
                      <Typography styledAs="smallBody" weight={500}>{priorAuthReviewInfo.reviewed_by}</Typography>
                      <ExtraSmallText styledAs="smallBody">accepted prior authorization</ExtraSmallText>
                      <span aria-hidden>·</span>
                      <ExtraSmallText styledAs="smallBody">{moment(priorAuthReviewInfo.reviewed_at).fromNow()}</ExtraSmallText>
                    </ResponseHeader>
                  </Response>
                </ResponsesContainer>
              )}

              <FormAndFooterContainer>
                <StyledCustomPaFormViewer id="custom-pa-form-viewer">
                  {formBody}
                </StyledCustomPaFormViewer>

                {priorAuthReviewInfo.status !== 'submitted' && (
                  <FormFooter>
                    {/* This div keeps the Accept button pushed to the right */}
                    <div>
                      <ResetFormButton
                        onClick={() => {
                          if (!updating && !resetPriorAuthReviewForm.isPending) {
                            resetPriorAuthReviewForm.mutate();
                          }
                        }}
                        isLoading={resetPriorAuthReviewForm.isPending}
                        hasLightBackground={true}
                      >
                        Reset original answers
                      </ResetFormButton>
                    </div>

                    {saveQuestionAnswer.isPending && <FooterLoadingSpinner />}

                    {formQuery.data?.form_status === 'FORM_STATUS_COMPLETED' && (
                      <AcceptButton
                        onClick={() => {
                          if (!updating && !resetPriorAuthReviewForm.isPending) {
                            acceptPriorAuthReview.mutate();
                          }
                        }}
                        isLoading={updating}
                        hasLightBackground={true}
                      >
                        Accept
                      </AcceptButton>
                    )}
                  </FormFooter>
                )}
              </FormAndFooterContainer>
            </CenteredContent>
          </ContentOuterWrapper>
        </TopNavAndContent>
        <Sidebar $isCollapsed={isSidebarCollapsed}>
          <SidebarTopBar />
          <DrugPanel drug={priorAuthReviewQuery.data.drug} />
          <PatientPanel patient={priorAuthReviewQuery.data.patient} />
          <PrescriberPanel prescriber={priorAuthReviewQuery.data.prescriber} />
        </Sidebar>
      </BodyContainer >
    );
  }

  return body;
};

export default PriorAuthReviewView;