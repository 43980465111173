import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PriorAuthReviewsResponse, client } from '../api';
import PageContainer from '../components/PageContainer';
import PriorAuthReviewsTable from '../components/priorAuthReviews/PriorAuthReviewsTable';
import { Outlet, useOutletContext } from 'react-router-dom';
import { priorAuthReviewsQueryKey } from '../queries';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';

type ContextType = { priorAuthReviewIds: string[] };

const PriorAuthReviewsTablePage: React.FC = () => {
    const [priorAuthReviewIds, setPriorAuthReviewIds] = useState<string[]>([]);

    const { data, isLoading, error } = useQuery<PriorAuthReviewsResponse>({
        queryKey: priorAuthReviewsQueryKey(),
        queryFn: () => {
            return client.get(`prior-auth-reviews`).then((res) => res.data);
        },
    });

    let body;
    if (isLoading) {
        body = <LoadingSpinner />;
    } else if (error) {
        body = 'Error, please try again';
    } else if (data) {
        // TODO: Use data.prior_auth_reviews when the endpoint is available
        if (data.prior_auth_reviews.length === 0) {
            body = 'No prior authorization reviews found';
        } else {
            // TODO: Use data.prior_auth_reviews when the endpoint is available
            body = <PriorAuthReviewsTable data={data.prior_auth_reviews} setPriorAuthReviewIds={setPriorAuthReviewIds} />;
        }
    }

    return (
        <>
            <PageContainer title="Prior Auth Reviews">{body}</PageContainer>
            <Outlet context={{ priorAuthReviewIds } satisfies ContextType} />
        </>
    );
};

export function usePriorAuthReviewIds() {
    return useOutletContext<ContextType>();
}

export default PriorAuthReviewsTablePage;
