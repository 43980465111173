import styled from 'styled-components';
import { ButtonSubmittingSpinner } from 'design-system/src/components/ButtonSubmittingSpinner';

const StyledButton = styled.button`
  height: 44px;
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  font-weight: 600;
  background: var(--purple);
  color: var(--white);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &[disabled] {
    background: var(--border-gray);
    cursor: not-allowed;
  }
`;

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  // If true, shows a loading spinner instead of the children
  isLoading?: boolean;
  // If true, uses a black loading spinner instead of a white one when isLoading is true
  hasLightBackground?: boolean;
}

/**
 * Button that supports loading state
 */
export const Button: React.FC<ButtonProps> = ({
  children,
  isLoading,
  hasLightBackground,
  ...props
}) => {
  return (
    <StyledButton {...props}>
      {isLoading ? <ButtonSubmittingSpinner hasLightBackground={hasLightBackground} /> : children}
    </StyledButton>
  );
};