import styled from 'styled-components';
import { Typography } from '../Typography/Typography';

type ToastVariant = 'default' | 'error' | 'success';

const StyledToast = styled.div<{ $variant: ToastVariant }>`
  padding: 0.75rem 1rem;
  border-radius: var(--border-radius-medium);
  width: fit-content;
  border-width: 1px;
  border-style: solid;

  ${({ $variant }) => {
    if ($variant === 'error') {
      return `
        // one-off red color lighter than light-red
        background-color: #ffd1d2;
        border-color: #ffd1d2;
        `;
    } else if ($variant === 'success') {
      return `        
        // one-off red color lighter than light-red
        background-color: #d4f0e1;
        border-color: #d4f0e1;
      `;
    } else {
      return `
        // default variant
        background-color: var(--black);
        border-color: var(--black);
      `;
    }
  }}
`;

const ToastChildren = styled(Typography) <{ $variant: ToastVariant }>`
  color: ${({ $variant }) => {
    if ($variant === 'error') {
      return 'var(--dark-red)';
    } else if ($variant === 'success') {
      return 'var(--dark-green)'
    } else {
      return 'var(--light-surface)';

    }
  }}
`;

export type ToastProps = {
  children: React.ReactNode;
  className?: string;
  variant?: ToastVariant;
};

/**
 * A custom toast. Intended to be used with react-hot-toast.
 * 
 * Example usage:
 * import toast from "react-hot-toast";
 * [...]
 * onClick={() => toast.custom(() => <Toast>A very important message</Toast>)}
 */
export const Toast = ({
  children,
  className,
  variant = 'default',
}: ToastProps) => {
  return (
    <StyledToast className={className} $variant={variant}>
      <ToastChildren styledAs="smallBody" weight={500} $variant={variant}>
        {children}
      </ToastChildren>
    </StyledToast>
  );
};