import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import styled from 'styled-components';
import toast from "react-hot-toast";
import { AxiosError } from 'axios';
// TODO: swap in design system components once we have them
import { Switch, Checkbox } from '@mui/material';

import { client, NotificationData, NotificationUpdateData, ErrorResponse } from '../api';
import PageContainer from '../components/PageContainer';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { SubmitButton } from '../components/signup/SubmitButton/SubmitButton';
import { Toast } from "design-system/src/components/Toast/Toast";
import {
    Table,
    TableCell,
    TableContainer,
} from '../components/Table';

const SubHeading = styled(Typography)`
  margin: 2rem 0 1rem;
`;

const HelperText = styled(Typography)`
  font-style: italic;
  padding-top: 0.5rem;
  padding-bottom: 1.25rem;
`;

const CustomTableContainer = styled(TableContainer)`
    margin-bottom: 1rem;
    // Allow table to take natural width but enable scrolling if needed
    width: fit-content;
    // Screen width - sidebar width - body padding
    max-width: calc(100vw - var(--nav-sidebar-width) - 2.5rem);
    // Add fixed height to enable vertical scrolling
    max-height: 36rem;
    overflow-x: auto;
    overflow-y: auto;

    // Add position relative for sticky positioning context
    position: relative;

    // Style for sticky header
    thead tr {
        position: sticky;
        top: 0;
        background: white;
        z-index: 2;
    }
    // Style for sticky first column
    th:first-child,
    td:first-child {
        position: sticky;
        left: 0;
        background: white;
        z-index: 1;
    }
    // Handle corner cell (intersection of sticky header and column)
    thead tr th:first-child {
        z-index: 3;
    }
`;

const CustomTableCell = styled(TableCell)`
    padding-left: 0.5rem;
    padding-right: 1.5rem;
    min-width: 5rem;
`;

const checkboxStyling = {
    color: `var(--purple)`,

    // Below selectors override MUI default styles
    // target Checkbox styles
    '&.Mui-checked': {
        color: `var(--purple)`,
    },
    // target Switch styles
    // note: using !important as a quick solution,
    // for some reason Switch selectors are more specific
    '.Mui-checked': {
        color: `var(--purple) !important`,
    },
    '.Mui-checked+.MuiSwitch-track': {
        backgroundColor: `var(--purple) !important`,
    }
};

/**
 * TODO: currently this page is an admin view
 * where you can set notification preferences for all users
 * in an organization.
 *
 * Instead, we should introduce the concept of an Admin role
 * -- for non-Admin users, they'll only be able to
 * edit their own notification preferences.
 */
const SettingsPage: React.FC = () => {
    const { data, isLoading, error } = useQuery<NotificationData>({
        queryKey: ["notification-settings"],
        queryFn: () => {
            return client.get(`notification/settings`).then((res) => res.data);
        },
    });
    const [memberSettings, setMemberSettings] = useState<NotificationUpdateData>({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Also store memberSettings in local state so we can use conditional logic in the table
    useEffect(() => {
        if (data) {
            const settings: NotificationUpdateData = {};
            data.members.forEach((member) => {
                settings[member.id] = {
                    email_address: member.email_address,
                    notifications: member.trusted_metadata.notifications,
                    npis: member.trusted_metadata.npis
                };
            });
            setMemberSettings(settings);
        }
    }, [data]);

    const submit = useMutation<{}, AxiosError<ErrorResponse>, NotificationUpdateData>({
        mutationFn: async (data) => {
            if (isSubmitting) {
                return;
            }
            setIsSubmitting(true);
            // TODO: it'd be nice to check for changes from the existing state
            // and only send the diff
            return client.post(`notification/settings/save`, data).then((res) => res.data);
        },
        onSuccess: () => {
            toast.custom(() => <Toast variant='success'>Settings saved</Toast>);
        },
        onError: () => {
            toast.custom(() => <Toast variant='error'>Error saving settings</Toast>);
        },
        onSettled: () => {
            setIsSubmitting(false);
        }
    });

    let body;
    if (isLoading) {
        body = <LoadingSpinner />;
    } else if (error) {
        body = 'Error, please try again';
    } else if (data) {
        const allNPIs = data.providers.map((provider) => provider.npi);
        body = (
            <>
                <SubHeading as="h2" styledAs="h3">Notification preferences</SubHeading>
                <Typography>Turn on providers to receive email notifications for.</Typography>
                <CustomTableContainer>
                    <Table>
                        <thead>
                            <tr>
                                <CustomTableCell>Account</CustomTableCell>
                                <CustomTableCell>Enable email notifications</CustomTableCell>
                                <CustomTableCell>Subscribe to all providers</CustomTableCell>
                                {data.providers.map((provider) => (
                                    <CustomTableCell key={provider.npi}>
                                        <div>{provider.name}</div>
                                        <Typography styledAs="smallBody">NPI: {provider.npi}</Typography>
                                    </CustomTableCell>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(memberSettings).map(([id, settings]) => {
                                const { email_address, notifications: notificationsEnabled, npis } = settings;

                                return (
                                    <tr key={id}>
                                        <CustomTableCell>{email_address}</CustomTableCell>
                                        {/* Enable notifications */}
                                        <CustomTableCell>
                                            <Switch
                                                sx={checkboxStyling}
                                                checked={!!notificationsEnabled}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setMemberSettings({
                                                        ...memberSettings,
                                                        [id]: {
                                                            ...settings,
                                                            notifications: e.target.checked
                                                        }
                                                    });
                                                }}
                                            />
                                        </CustomTableCell>
                                        {/* Toggle subscribing to all or no providers */}
                                        <CustomTableCell>
                                            <Checkbox
                                                sx={checkboxStyling}
                                                disabled={!notificationsEnabled}
                                                checked={!!notificationsEnabled && (!npis || npis.length === allNPIs.length)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setMemberSettings({
                                                        ...memberSettings,
                                                        [id]: {
                                                            ...settings,
                                                            npis: e.target.checked ? allNPIs : []
                                                        }
                                                    });
                                                }}
                                            />
                                        </CustomTableCell>
                                        {/* Subscribe to individual providers */}
                                        {data.providers.map((provider) => (
                                            <CustomTableCell key={provider.npi}>
                                                <Checkbox
                                                    sx={checkboxStyling}
                                                    disabled={!notificationsEnabled}
                                                    checked={!!notificationsEnabled && (!npis || npis.includes(provider.npi))}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setMemberSettings({
                                                            ...memberSettings,
                                                            [id]: {
                                                                ...settings,
                                                                npis: e.target.checked ? [...(npis || []), provider.npi] : (npis || []).filter((npi) => npi !== provider.npi)
                                                            }
                                                        });
                                                    }}
                                                />
                                            </CustomTableCell>
                                        ))}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </CustomTableContainer>
                <SubmitButton onClick={() => submit.mutate(memberSettings)} isLoading={isSubmitting}>
                    Save settings
                </SubmitButton>
                <HelperText>Please allow up to 24 hours for changes to take effect.</HelperText>
            </>
        );
    }

    return (
        <PageContainer title="Settings">
            {body}
        </PageContainer>
    );
};

export default SettingsPage;
