import React, { useCallback, useContext, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import toast from "react-hot-toast";
import { Toast } from "design-system/src/components/Toast/Toast";
import {
  PopoverMenu,
  PopoverMenuButton,
  PopoverMenuSection,
  PopoverMenuHeading,
  PopoverMenuItems,
  PopoverMenuItem,
  PopoverMenuItemButton,
} from '../PopoverMenu';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { FileInfoResponse, fileTypes, FileType, apiHost, client } from '../../api';
import { taskFileQueryKey } from '../../queries';
import { useQuery } from '@tanstack/react-query';
import { TaskContext } from './contexts';
import downloadFile from 'design-system/src/assets/icons/downloadFile.svg';

const FileDisplay = styled.div<{ $solid?: boolean; $active?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  // Pushes the "Remove" button down; necessary for files with excess whitespace
  // because there's no category
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid var(--border-gray);
  background: var(--white);
  width: 150px;
  margin-right: 0.5rem;
`;

const AttachedFileDisplay = styled(FileDisplay)`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  margin-right: 0;
`;

FileDisplay.defaultProps = {
  $solid: false,
  $active: false,
};

const uploadBoxStyles = `
  display: flex;
  height: 44px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  cursor: pointer;
`;

const UploadBox = styled.div`
  ${uploadBoxStyles}
`;

const UploadPopoverButton = styled(PopoverMenuButton)`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 2px var(--purple);
  }
  ${uploadBoxStyles}
`;

const FileDownloadLinkContainer = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 6px;
  flex-shrink: 0;
  display: flex;
  img {
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
  }
`;

const FileDownloadLink: React.FC<{ fileId: string; taskId: string }> = ({ fileId, taskId }) => {
  return (
    <a href={`${apiHost}/tasks/${taskId}/file/${fileId}/download`} download>
      <FileDownloadLinkContainer>
        <img src={downloadFile} alt="Download" />
      </FileDownloadLinkContainer>
    </a>
  );
};

const getFileTypeUI = (fileType: FileType | string) => {
  return fileType === 'Insurance Card Both Sides' ? 'Insurance Card' : fileType;
};

const loadingText = 'Loading...';

const formatFileName = (filename: string) => {
  if (filename.length > 14) {
    return `${filename.slice(0, 10)}...${filename.slice(-4)}`;
  }
  return filename;
};

const getFileName = (data: FileInfoResponse | undefined) => {
  if (!data) {
    return loadingText;
  }
  return formatFileName(data.filename);
};

const getFileType = (isLoading: boolean, data: FileInfoResponse | undefined) => {
  if (isLoading) {
    return loadingText;
  }
  const fileType = data?.tags.find((tag) => tag.name === "Document Type")?.value;
  if (!fileType || !fileTypes.includes(fileType)) {
    return '';
  }
  return formatFileName(getFileTypeUI(fileType));
};

export const FileUploader: React.FC = () => {
  const [uploadingFileType, setUploadingFileType] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const { refetchTask, taskId, setUpdating } = useContext(TaskContext);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setUpdating(true);
    setUploading(true);
    await Promise.all(
      acceptedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('document_type', uploadingFileType);

        try {
          await client.post(`tasks/${taskId}/file`, formData);
        } catch (error) {
          toast.custom(() => <Toast variant='error'>An error occurred</Toast>);
          console.error('Error uploading file:', error);
          setHasError(true);
        }
      }),
    );
    await refetchTask();
    setUpdating(false);
    setUploading(false);
    setUploadingFileType('');
  }, [uploadingFileType]);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    // We're removing click and keyboard functionality so we can open our dropdown menu
    // on click instead
    noClick: true,
    noKeyboard: true,
    // We're overriding drag for now because it's not working with our popover menu
    noDrag: true,
    // Make sure to reset the state if the user cancels out of the file selection dialog
    onFileDialogCancel: () => setUploadingFileType(''),
  });

  let buttonLabel: string;
  if (uploading) {
    buttonLabel = 'Uploading...';
  } else {
    buttonLabel = hasError ? 'Error Uploading File' : 'Attach file';
  }

  useEffect(() => {
    // We're passing the file type to the file uploader via state + useEffect
    // because dropzone doesn't pass the button target information along
    if (!!uploadingFileType) {
      open();
    }
  }, [uploadingFileType]);

  return (
    <FileDisplay {...getRootProps()}>
      <input {...getInputProps()} />

      <PopoverMenu>
        <UploadPopoverButton>{buttonLabel}</UploadPopoverButton>

        <PopoverMenuItems>
          <PopoverMenuSection>
            <PopoverMenuHeading>
              What type of file are you uploading?
            </PopoverMenuHeading>

            {fileTypes.map((fileType: FileType) => {
              return (
                <PopoverMenuItem key={`file-type-option-${fileType}`}>
                  <PopoverMenuItemButton
                    data-file-type={fileType}
                    onClick={(event) => {
                      const target = event.target as HTMLElement;
                      setUploadingFileType(target.dataset.fileType || '');
                    }}
                  >
                    {getFileTypeUI(fileType)}
                  </PopoverMenuItemButton>
                </PopoverMenuItem>
              );
            })}
          </PopoverMenuSection>
        </PopoverMenuItems>
      </PopoverMenu>
    </FileDisplay >
  );
};

const FileInfo = styled.div`
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  line-height: 114.286%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
`;

const FileName = styled.div`
  color: var(--black);
  font-weight: 500;
`;
interface UploadedFileProps {
  fileId: string;
  onFileDelete?: () => void;
}

export const UploadedFile: React.FC<UploadedFileProps> = ({ fileId, onFileDelete }) => {
  const { taskId } = useContext(TaskContext);

  const query = useQuery<FileInfoResponse>({
    queryKey: taskFileQueryKey(fileId),
    queryFn: async () => {
      const res = await client.get(`/tasks/${taskId}/file/${fileId}`);
      return res.data;
    },
  });

  const filename = getFileName(query.data);
  const fileType = getFileType(query.isLoading, query.data);

  return (
    <FileDisplay $solid>
      <FileInfo>
        <FileName title={filename}>{filename}</FileName>
        <Typography title={fileType} styledAs='smallBody'>{fileType}</Typography>
      </FileInfo>
      {onFileDelete && <UploadBox onClick={() => onFileDelete()}>Remove</UploadBox>}
    </FileDisplay>
  );
};

export const AttachedFile: React.FC<{ fileId: string }> = ({ fileId }) => {
  const { taskId } = useContext(TaskContext);

  const query = useQuery<FileInfoResponse>({
    queryKey: taskFileQueryKey(fileId),
    queryFn: async () => {
      const res = await client.get(`/tasks/${taskId}/file/${fileId}`);
      return res.data;
    },
  });

  const filename = getFileName(query.data);

  return (
    <AttachedFileDisplay>
      <FileDownloadLink fileId={fileId} taskId={taskId} />
      <FileInfo>
        <FileName title={filename}>{filename}</FileName>
      </FileInfo>
    </AttachedFileDisplay>
  );
};
