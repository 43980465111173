import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Content, Overlay } from '../Slideout';
import { TaskContext } from './contexts';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TaskResponse, client } from '../../api';
import { taskQueryKey, tasksQueryKey } from '../../queries';
import TaskView from './TaskView';
import { useTasksIds } from "../../pages/TasksTablePage";

const TaskOverlay: React.FC = () => {
  const params = useParams();
  const taskId = params.taskId!;
  const { taskIds } = useTasksIds();

  const queryClient = useQueryClient();
  const taskQuery = useQuery<TaskResponse>({
    queryKey: taskQueryKey(taskId),
    queryFn: async () => {
      const res = await client.get(`tasks/${taskId}`);
      return res.data;
    },
  });

  const [updating, setUpdating] = useState(false);

  return (
    <Overlay>
      <Content>
        <TaskContext.Provider
          value={{
            taskId,
            taskQuery,
            updating,
            setUpdating,
            refetchTask: async () =>
              queryClient.invalidateQueries({
                queryKey: taskQueryKey(taskId),
                exact: true,
              }),
            onTaskUpdate: async () => {
              queryClient.invalidateQueries({
                queryKey: tasksQueryKey(),
              });
              queryClient.invalidateQueries({
                queryKey: taskQueryKey(taskId),
              });
              setUpdating(false);
            },
            taskIds: taskIds || [],
          }}
        >
          <TaskView />
        </TaskContext.Provider>
      </Content>
    </Overlay>
  );
};

export default TaskOverlay;
