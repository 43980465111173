import React, { useCallback, Dispatch, SetStateAction, useEffect } from 'react';
import moment from 'moment';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  ColumnFiltersState,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';

import type { RequestSummary } from '../../api';
import { TableDropdownFilter } from '../TableDropdownFilter/TableDropdownFilter';
import { sortOfficeNames } from '../../utils';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  ActionButton,
  ClickableRow,
  FilterBar,
  FilterBarRow,
  FilterGroup,
  FilterLabel,
  SearchInput,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  HeaderSortingIndicator,
  OfficeNameTableDropdownFilter,
} from '../Table';

const columnHelper = createColumnHelper<RequestSummary>();

interface RequestTableProps {
  data: RequestSummary[];
  setRequestIds: Dispatch<SetStateAction<string[]>>;
}

export const RequestTable: React.FC<RequestTableProps> = ({ data, setRequestIds }) => {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([
    { id: 'status', value: searchParams.get('status') || 'completed' },
  ]);

  const typeStrings: string[] = [];
  const prescriberStrings: string[] = [];
  const officeNameStrings: string[] = [];
  data.forEach((request) => {
    typeStrings.push(request.type);
    if (request.prescriber_name) {
      prescriberStrings.push(request.prescriber_name);
    }
    if (request.office_name) {
      officeNameStrings.push(request.office_name);
    }
  });
  const sortedOfficeNameStrings = sortOfficeNames(officeNameStrings);

  const columns = [
    columnHelper.accessor('created_at', {
      header: 'Request Date',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      enableGlobalFilter: false,
      cell: (info) => {
        const status = info.getValue();
        return status === 'queued' ? 'Open' : 'Complete';
      },
    }),
    columnHelper.accessor('text', {
      header: 'Request',
      enableGlobalFilter: false,
      cell: (info) => {
        const text = info.getValue();
        return text.length > 50 ? text.slice(0, 47) + '...' : text;
      },
    }),
    columnHelper.accessor('type', {
      header: 'Type',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('patient_name', {
      header: 'Patient',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('prescriber_name', {
      header: 'Prescriber',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('dob', {
      header: 'Patient Date of Birth',
      enableGlobalFilter: false,
      cell: (info) => moment(info.getValue()).format('MM/DD/YYYY'),
    }),
    columnHelper.accessor('office_name', {
      header: 'Office',
      enableGlobalFilter: true,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    enableGlobalFilter: true,
    globalFilterFn: 'includesString',
    initialState: { columnVisibility: { status: false } },
  });

  const setStatus = useCallback(
    (status: string) => {
      setSearchParams({ status });
      table.getColumn('status')!.setFilterValue(status);
    },
    [setSearchParams, table],
  );

  const rows = table.getRowModel().flatRows;
  const rowIds = rows.map((row) => row.original.id);

  useEffect(() => {
    // Update the list of ids in the parent component whenever the user filters or sorts the table
    setRequestIds(rowIds);

    // TODO: figure out how to get the rowIds to update when the user sorts the table
    // because putting rowIds in the dependency array is causing an infinite loop
    // }, [columnFilters, rowIds]);
  }, [columnFilters]);

  return (
    <TableContainer>
      <FilterBar>
        <FilterBarRow>
          <FilterGroup>
            <FilterLabel
              $active={table.getColumn('status')!.getFilterValue() == 'queued'}
              onClick={() => setStatus('queued')}
            >
              Open Requests
            </FilterLabel>
            <FilterLabel
              $active={table.getColumn('status')!.getFilterValue() == 'completed'}
              onClick={() => setStatus('completed')}
            >
              Tandem Responses
            </FilterLabel>
          </FilterGroup>
          <SearchInput
            label="Search by name"
            hideLabel={true}
            onChange={(e) => table.setGlobalFilter((e.target as HTMLInputElement).value)}
          />
          <ActionButton onClick={() => navigate('new')}>New request</ActionButton>
        </FilterBarRow>

        <FilterBarRow>
          <TableDropdownFilter
            optionStrings={typeStrings}
            label={'Type'}
            onChange={(tasktype: string) => { table.getColumn('type')!.setFilterValue(tasktype) }}
          />
          <TableDropdownFilter
            optionStrings={prescriberStrings}
            label={'Prescriber'}
            onChange={(prescriberName: string) => { table.getColumn('prescriber_name')!.setFilterValue(prescriberName) }}
          />
          <OfficeNameTableDropdownFilter
            optionStrings={sortedOfficeNameStrings}
            label={'Office Name'}
            onChange={(officeName: string) => { table.getColumn('office_name')!.setFilterValue(officeName) }}
          />
        </FilterBarRow>
      </FilterBar>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeader key={header.id} onClick={() => header.column.toggleSorting()}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    <HeaderSortingIndicator
                      isSorted={header.column.getIsSorted()}
                      sortDirection={header.column.getNextSortingOrder()}
                    />
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <ClickableRow
                $selected={row.original.id === requestId}
                key={row.original.id}
                onClick={() => navigate(row.original.id)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </ClickableRow>
            ))
          ) : (
            <tr>
              <TableCell colSpan={columns.length}>No results.</TableCell>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};
