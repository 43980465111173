import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

export type TypeaheadOption = {
  description: string;
  value: string;
};

type TypeaheadProps = {
  options: TypeaheadOption[];
  label: string;
  onChange: (value: string) => void;
  value?: string;
  // Allows the consumer to pass in a styled textfield
  textfieldComponent?: React.ElementType;
};

const Typeahead: React.FC<TypeaheadProps> = ({ options, label, onChange, value, textfieldComponent }) => {
  const getOptionForValue = (value: string) => {
    return options.find((option) => option.value === value);
  };

  const TextfieldToUse = textfieldComponent || TextField;

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option: TypeaheadOption) => option.description}
      value={value ? getOptionForValue(value) : undefined}
      onChange={(_, newValue) => {
        onChange(newValue ? newValue.value : '');
      }}
      renderInput={(params) => <TextfieldToUse {...params} label={label} variant="outlined" fullWidth />}
    />
  );
};

export default Typeahead;
