import React, { useMemo, useEffect } from 'react';
import { debounce } from "lodash";
import styled from 'styled-components';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage';
import { PriorAuthReviewFormAnswer } from '../../../api.tsx';

const AnswerGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 0.75rem;
`;

const CheckboxOrRadioChoice = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  label {
    cursor: pointer;
  }
`;

const CheckboxOrRadioInput = styled.input`
  cursor: pointer;
  margin: 0;
  height: 1.25rem;
  width: 1.25rem;
  accent-color: var(--purple);
`;

const CheckboxInput = styled(CheckboxOrRadioInput)`
  border-radius: var(--border-radius-small);
`;

const RadioInput = styled(CheckboxOrRadioInput)`
  border-radius: 50%;
`;

const SkipButton = styled.div`
  float: right;
  min-width: 150px;
`;

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
`;

const inputStyles = `
  width: 100% !important;
  border: 1px solid var(--medium-gray);
  background-color: var(--white);
  border-radius: 0.375rem;
  padding: 0.875rem 0.75rem 0.875rem 0.75rem;
  margin-top: 0.75rem;
  font-size: 0.9rem;
  font-style: normal;
  line-height: 1rem;
  color: var(--black);
  // It's safe to remove the resize handle because this textarea autoresizes
  resize: none;
`;

const StyledTextArea = styled(TextareaAutosize)`
  ${inputStyles}
`;

export const DateInput = styled.input`
  ${inputStyles}
`;

type BaseProps = {
  questionId: string;
  questionIndex: number;
  disabled: boolean;
};

type TextProps = BaseProps & {
  answer: PriorAuthReviewFormAnswer;
  onSave: (questionId: string, answerId: string, questionIndex: number, text: string) => void;
};

type RadioProps = BaseProps & {
  allowSkip: boolean;
  answers: PriorAuthReviewFormAnswer[];
  onChange: (questionId: string, answerId: string, questionIndex: number) => void;
};

type CheckboxProps = BaseProps & {
  allowSkip: boolean;
  answers: PriorAuthReviewFormAnswer[];
  onChange: (
    questionId: string,
    answerId: string,
    questionIndex: number,
    selectedOptions: string[],
    checked: boolean,
  ) => void;
};

export const NonQuestion = ({ answer, questionId, questionIndex, onSave, disabled }: TextProps) => {
  useEffect(() => {
    if (!disabled) {
      onSave(questionId, answer.identifier, questionIndex, '');
    }
  }, []);

  return <TextAreaContainer />;
};

export const TextField = React.memo(({ answer, questionId, questionIndex, onSave, disabled }: TextProps) => {
  const validateInput = (inputText: string) => {
    if (
      answer.regex_validations === null ||
      answer.regex_validations === undefined ||
      answer.regex_validations === ''
    ) {
      return true;
    }

    const regex = new RegExp(answer.regex_validations);
    return regex.test(inputText);
  };

  const [text, setText] = React.useState(answer.answer_input_text || '');
  // TODO: deal with null-ish `regexValidations` e.g. `null`, `undefined`, ""
  const [isValid, setIsValid] = React.useState(validateInput(text));

  const handleDebounceFn = (text: string) => {
    onSave(questionId, answer.identifier, questionIndex, text);
  };
  const debounceFn = useMemo(() => debounce(handleDebounceFn, 1000), []);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = event.target.value;
    setText(inputText);
    const isValid = validateInput(inputText);
    setIsValid(isValid);
    if (isValid && !disabled) {
      debounceFn(event.target.value);
    }
  };

  return (
    <TextAreaContainer>
      <StyledTextArea
        onChange={handleTextChange}
        value={text}
        disabled={disabled}
      />
      {!isValid && <ErrorMessage>Invalid input.</ErrorMessage>}
    </TextAreaContainer>
  );
});

export const Radio = React.memo(({ answers, questionId, questionIndex, disabled, onChange, allowSkip }: RadioProps) => {
  return (
    <AnswerGroup>
      {answers.map((option) => {
        return (
          <CheckboxOrRadioChoice key={option.identifier}>
            <RadioInput
              id={option.identifier}
              type="radio"
              name={questionId}
              value={option.identifier}
              checked={!!option.is_selected}
              disabled={disabled}
              onChange={() => onChange(questionId, option.identifier, questionIndex)}
            />
            <label htmlFor={option.identifier}>{option.description}</label>
          </CheckboxOrRadioChoice>
        );
      })}
      {!disabled && allowSkip && <SkipButton onClick={() => onChange(questionId, '', questionIndex)}>Skip question</SkipButton>}
    </AnswerGroup>
  );
});

export const Checkbox = React.memo(({ answers, questionId, questionIndex, disabled, onChange, allowSkip }: CheckboxProps) => {
  const selectedOptions = answers.filter((option) => option.is_selected).map((option) => option.identifier);

  return (
    <AnswerGroup>
      {answers.map((option) => {
        return (
          <CheckboxOrRadioChoice key={option.identifier}>
            <CheckboxInput
              id={option.identifier}
              type="checkbox"
              name={questionId}
              value={option.identifier}
              checked={selectedOptions.includes(option.identifier)}
              disabled={disabled}
              onChange={(e) =>
                onChange(questionId, option.identifier, questionIndex, selectedOptions, e.target.checked)
              }
            />
            <label htmlFor={option.identifier}>{option.description}</label>
          </CheckboxOrRadioChoice>
        );
      })}
      {!disabled && allowSkip && (
        <SkipButton onClick={() => onChange(questionId, '', questionIndex, [], false)}>Skip question</SkipButton>
      )}
    </AnswerGroup>
  );
});