import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { RenderHtmlString } from 'design-system/src/components/RenderHtmlString/RenderHtmlString';
import { Content, Overlay } from '../Slideout';
import { useQuery } from '@tanstack/react-query';
import { useRequestIds } from "../../pages/RequestsTablePage";
import { RequestResponse, client } from '../../api';
import styled from 'styled-components';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import {
  BodyContainer,
  TopNavAndContent,
  ContentOuterWrapper,
  CenteredContent,
  Hairline,
} from '../tableItemDetailViewComponents/Containers';
import { Typography } from 'design-system/src/components/Typography/Typography'

const ExtraInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1.5rem 0;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const FieldLabel = styled(Typography).attrs({ styledAs: 'smallBody' })`
  color: var(--dark-gray);
  min-width: 100px;
  padding-right: 1rem;
`;

const OpenResponseText = styled(Typography)`
  color: var(--dark-gray);
  font-style: italic;
`;

const RequestOverlay: React.FC = () => {
  const params = useParams();
  const query = useQuery<RequestResponse>({
    queryKey: ['request', params.requestId],
    queryFn: () => {
      return client.get(`requests/${params.requestId}`).then((res) => res.data);
    },
  });

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const showSidebar = query.data?.drug && query.data.patient && query.data.prescriber;

  const { requestIds } = useRequestIds();
  const requestIdsToUse = requestIds || [];

  let body;
  if (query.isLoading) {
    body = <LoadingSpinner />;
  }
  if (query.error) {
    body = 'Error, please try again';
  }
  if (query.data) {
    const breadcrumbCurrent = query.data.drug && query.data.patient
      ? `${query.data.drug?.name} (${query.data.patient?.last_name})`
      : 'Request';

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav
            itemId={query.data.request.id}
            listIds={requestIdsToUse}
            itemBaseUrl={"/requests"}
            urlOfTable="/requests"
            breadcrumbOrigin='Requests'
            breadcrumbCurrent={breadcrumbCurrent}
            isSidebarCollapsed={isSidebarCollapsed}
            setIsSidebarCollapsed={setIsSidebarCollapsed}
            noSidebar={!showSidebar}
          />

          <ContentOuterWrapper>
            <CenteredContent>
              <Typography styledAs='title'>{query.data.request.type}</Typography>

              <ExtraInfo>
                <FieldGroup>
                  <FieldLabel>First name</FieldLabel>
                  <Typography>{query.data.request.first_name}</Typography>
                </FieldGroup>
                <FieldGroup>
                  <FieldLabel>Last name</FieldLabel>
                  <Typography>{query.data.request.last_name}</Typography>
                </FieldGroup>
                <FieldGroup>
                  <FieldLabel>Date of birth</FieldLabel>
                  <Typography>{moment(query.data.request.dob).format('MM/DD/YYYY')}</Typography>
                </FieldGroup>
              </ExtraInfo>

              <Typography><RenderHtmlString>{query.data.request.text}</RenderHtmlString></Typography>

              <Hairline />

              {query.data.request.status == 'completed' ? (
                <Typography><RenderHtmlString>{query.data.request.response}</RenderHtmlString></Typography>
              ) : (
                <OpenResponseText>Tandem hasn't responded to this request yet. Please check back soon.</OpenResponseText>
              )}
            </CenteredContent>
          </ContentOuterWrapper>
        </TopNavAndContent>

        {(!isSidebarCollapsed && query.data.drug && query.data.patient && query.data.prescriber) && <Sidebar>
          <SidebarTopBar />
          <DrugPanel drug={query.data.drug} />
          <PatientPanel patient={query.data.patient} />
          <PrescriberPanel prescriber={query.data.prescriber} />
        </Sidebar>}
      </BodyContainer>
    );
  }

  return (
    <Overlay>
      <Content>
        {body}
      </Content>
    </Overlay>
  );
};

export default RequestOverlay;
