import styled, { keyframes } from 'styled-components';
import loading from '../../assets/icons/loading.svg';

const LoadingSpinnerContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spin = keyframes`
from {
  transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledLoadingSpinner = styled.img`
  animation: ${spin} 1.5s linear infinite;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

/**
 * An animated spinner for page content loading states. This component will fill the height of the viewport and center the spinner.
 */
export const LoadingSpinner = ({ className }: { className?: string }) => {
  return (
    <LoadingSpinnerContainer className={className ? className : ''}>
      <StyledLoadingSpinner src={loading} />
    </LoadingSpinnerContainer>
  );
};