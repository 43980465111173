import React from 'react';
import styled from 'styled-components';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { NavSidebar } from './NavSidebar';
import { TopNavbar } from './TopNavbar/TopNavbar';

const Container = styled.div<{ $backgroundColor: BackgroundColor; $navbarPlacement: NavbarPlacement }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor === 'cream' ? 'var(--light-surface);' : 'var(--white)'};
  height: 100vh;
  height: 100dvh; //iOS safari
  display: flex;
  flex-direction: ${({ $navbarPlacement }) => $navbarPlacement === 'top' ? 'column;' : 'row'};
`;

const MainContent = styled.div<{ $navbarPlacement: NavbarPlacement }>`
  flex: 1;
  padding: 1.25rem;
  margin-left: ${({ $navbarPlacement }) => $navbarPlacement === 'top' ? '0;' : 'var(--nav-sidebar-width)'};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

// TODO: consider updating 'cream' name when the color tokens are updated
type BackgroundColor = 'white' | 'cream';
type NavbarPlacement = 'side' | 'top';

interface PageContainerProps {
  title?: string;
  children: React.ReactNode;
  onBack?: () => void;
  centered?: boolean;
  backgroundColor?: BackgroundColor;
  navbarPlacement?: NavbarPlacement;
}

const PageContainer: React.FC<PageContainerProps> = ({ title, children, backgroundColor = 'white', navbarPlacement = 'side' }) => {
  return (
    <Container $backgroundColor={backgroundColor} $navbarPlacement={navbarPlacement} >
      {navbarPlacement === 'side' ? <NavSidebar /> : <TopNavbar />}
      <MainContent $navbarPlacement={navbarPlacement}>
        {title && <TitleContainer>
          <Typography styledAs="h5" as="h1">{title}</Typography>
        </TitleContainer>}
        {children}
      </MainContent>
    </Container>
  );
};

export default PageContainer;
