import styled from "styled-components";

export const ControllerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    > div {
    margin-top: 0;
    }
`;