export const formatDateOfBirth = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Office names could be addresses, so we want to make sure anything that's an address is placed after actual office names
// An address starting with a number is our approximation of identifying an address
export const sortOfficeNames = (officeNameStrings: string[]): string[] => {
  return officeNameStrings.sort((a, b) => {
    const startsWithNumber = (str: string) => /\d/.test(str[0]);

    // If `a` starts with a number and `b` does not, `a` goes after `b`
    if (startsWithNumber(a) && !startsWithNumber(b)) return 1;

    // If `b` starts with a number and `a` does not, `b` goes after `a`
    if (!startsWithNumber(a) && startsWithNumber(b)) return -1;

    // Otherwise, sort alphabetically
    return a.localeCompare(b);
  });
};
